:root[data-theme="lightTheme"] {
  --primary: #F3B2D0;
  --secondPrimary: #EB008C;
  --secondary: #C8DCFF;
  --dark: #f5efe1;
  --font: #322D68;
  --darkRose: #EB008C;
  --lightRose: #F3B2D0;
  --primary_shadow: #131a1a;
  --success: #d7ff85;
  --shadowCustomButtonPrimary: #F3B2D0;
  --shadowCustomButtonTimer: #F3B2D0;
  --shadowCustomButtonSecondary: #C8DCFF;
  --background: #ffffff;
  --white: #ffffff; 
  --disableText: rgba(50, 45, 104,0.3);
  --error: #dc3545;
  --inputBorderFocus: #322D68;
  --inputBorderFocusDisable:rgba(255,255, 255,0.3); 
  --inputBorder: #ffffff;
  --keyboardBackground: #C8DCFF;
  --keyboardKeys: #ffffff;
  --toastBackgroud: #C8DCFF;
  --toastTextColor: #322D68;
  --toastIconColor: #322D68;
  --secondaryOpacity: #e0ebff;
  --lokkLogo: #322D68;
}

:root[data-theme="darkTheme"] {
  --primary: #EB008C;
  --secondPrimary: #F3B2D0;
  --darkRose: #EB008C;
  --lightRose: #F3B2D0;
  --white: #ffffff; 
  --secondary: #ffffff;
  --dark: #f5efe1;
  --font: #322D68;
  --primary_shadow: #131a1a;
  --success: #d7ff85;
  --shadowCustomButtonPrimary: #EB008C;
  --shadowCustomButtonTimer: #F3B2D0;
  --shadowCustomButtonSecondary: #25233b;
  --background: #322D68;
  --disableText: rgba(50, 45, 104,0.3);
  --inputBorderFocusDisable:rgba(255,255, 255,0.3); 
  --inputBorderFocus: #EB008C;
  --inputBorder: #ffffff;
  --error: #dc3545;
  --keyboardBackground: #ececec;
  --keyboardKeys: #ffffff;
  --toastBackgroud: #ffffff;
  --toastTextColor: #322D68;
  --toastIconColor: #322D68;
  --secondaryOpacity: #ffffffce;
  --lokkLogo: #ffffff;
}


// @import "../../../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Latina';
  src: url('../fonts/300.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Latina';
  src: url('../fonts/400.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Latina';
  src: url('../fonts/bold.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Latina", sans-serif !important;
  color: var(--font);
}

html {
  scroll-behavior: smooth;
}

.text-1 {
  font-size: 3.1em !important;
}
.text-2 {
  font-size: 2.8em !important;
}
.text-3 {
  font-size: 2.48em !important;
}
.text-4 {
  font-size: 2.2em !important;
}
.text-5 {
  font-size: 1.8em !important;
}
.text-6{
  font-size: 1.6em !important;
}

.weight-300 {
  font-weight: 300 !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.bold{
  font-weight: bold !important;
}

.h-fit-content {
  height: fit-content !important;
}

.w-fit-content {
  width: fit-content !important;
}

.border-radius {
  border-radius: 0.5rem !important;
}

.noScroll::-webkit-scrollbar {
  display: none !important;
}

.timerAnim {
  animation: timer linear;
}

.error {
  animation: error forwards 0.5s;
}

.pulse-lightTheme {
  animation: pulse-lightTheme forwards 0.5s;
}

.pulse-darkTheme {
  animation: pulse-darkTheme forwards 0.5s;
}

.noScroll::-webkit-scrollbar {
  display: none !important;
}

.scroll::-webkit-scrollbar {
  width: 12px !important;
  border-radius: 0;
  background-color: #f5f5f5 !important;
  cursor: pointer !important;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: var(--primary) !important;
  height: 10px !important;
  position: absolute;
  cursor: pointer !important;
}

.accordion {
  padding: 0;
  margin: 0;

  & .accordion-item {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: var(--secondary) !important;
    color: var(--font) !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-header {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: var(--secondary) !important;
    outline: 0 !important;
    color: var(--font) !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: var(--secondary) !important;
    min-height: 50px;
    max-height: fit-content;
    outline: 0 !important;
    color: var(--font) !important;
    font-family: "Nunito", sans-serif !important;
    box-shadow: none !important;
  }

  & .accordion-button:after {
    display: none !important;
    outline: 0 !important;
    color: var(--font) !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button:not(.collapsed)::after {
    border: 0 !important;
    outline: 0 !important;
    color: var(--font) !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button:focus {
    box-shadow: none !important;
    border: 0 !important;
    outline: 0 !important;
    color: var(--font) !important;
    font-family: "Nunito", sans-serif !important;
  }
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

#loading-bar-spinner.spinner {
  animation: loading-bar-spinner 0.9s linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 225px;
  height: 225px;
  border:  solid 8px transparent;
  border-top-color:  var(--primary) !important;
  border-left-color: var(--primary) !important;
  border-radius: 50%;
}

.priceScroll::-webkit-scrollbar {
  height: 8px !important;
}

.priceScroll::-webkit-scrollbar-thumb {
  background: var(--primary) !important;
  border-radius: 0.2em !important;
}

.priceScroll::-moz-scrollbar {
  height: 8px !important;
}

.priceScroll::-moz-scrollbar-thumb {
  background: var(--primary) !important;
  border-radius: 0.2em !important;
}

.priceScroll::-ms-scrollbar {
  height: 8px !important;
}

.priceScroll::-ms-scrollbar-thumb {
  background: var(--primary) !important;
  border-radius: 0.2em ;
}

.priceScroll::-o-scrollbar {
  height: 8px !important;
}

.priceScroll::-o-scrollbar-thumb {
  background: var(--primary);
  border-radius: 0.2em;
}

.priceScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.customInput-lightTheme {
  // border: 1.5px solid var(--font);
  background: var(--secondary) !important;
  outline:none !important;
  border-radius: 0.5rem !important;

  &::placeholder{
    color: var(--font) !important;
  }
  
  &:focus {
    outline:none !important;
    box-shadow: none !important;
    // border: 1.5px solid var(--font);   
    background: var(--secondary) !important;
    border-radius: 0.5rem !important;
  }
}

.customInput-darkTheme {
  // border: 1.5px solid var(--font);
  background: var(--secondary) !important;
  outline:none !important;
  border-radius: 0.5rem !important;

  &::placeholder{
    color: var(--font) !important;
  }
  
  &:focus {
    outline:none !important;
    box-shadow: none !important;
    // border: 1.5px solid red;
    background: var(--secondary) !important;
    border-radius: 0.5rem !important;
  }
}

.customInputError {
  border: 1.5px solid #dc3545 !important;
  outline:none !important;
  border-radius: 0.5rem !important;
  color: #dc3545 !important;
  animation: error forwards 0.5s;

  &::placeholder{
    color: #dc3545 !important
  }
  
  &:focus {
    outline:none !important;
    box-shadow: none !important;
    border: 1.5px solid #dc3545 !important;
    border-radius: 0.5rem !important;
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pulse-lightTheme {
  0% {
    box-shadow: 0 0 0 0px rgba(50, 45, 103, 1);
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(50, 45, 103, 0);
    transform: scale(1.1);
  }
}

@keyframes pulse-darkTheme {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(129, 59, 47, 0);
    transform: scale(1.1);
  }
}

@keyframes error {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(10px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-10px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(8px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-8px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}

@keyframes timer {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}


.customScroll::-webkit-scrollbar {
  width: 0.5em !important;
}
 
.customScroll::-webkit-scrollbar-thumb {
  background-color: var(--primary) !important;
  border-radius: 0.2rem !important;
}

.modal-content{
  background-color: transparent;
  border: none;
}

.hg-button{
  min-width: 70px !important;
  min-height: 70px !important;
  font-size: 2rem !important;
}

.container {
  position: relative;
  width: 500px;
  height: 500px;
  overflow: hidden;
}

.floating-element {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 75px solid transparent; /* Adjust size as needed */
  border-right: 75px solid transparent; /* Adjust size as needed */
  border-bottom: 97px solid #c8e6c3; /* Adjust height as needed */
  animation: floatAnimation 25s infinite linear;
}

.container1 {
  position: relative;
  width: 500px;
  height: 500px;
  overflow: hidden;
}

.floating-element1 {
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: #e6d2fa;
  border-radius: 50%;
  animation: floatAnimation1 25s infinite linear;
}

.container2 {
  position: relative;
  width: 500px;
  height: 500px;
  overflow: hidden;
}

.floating-element2 {
  position: absolute;
  animation: floatAnimation2 25s infinite linear;
  & .anim{
    width: 180px;
    height: auto;
    fill:#FFEB8B;
    animation: animClip 5s ease-in-out -3s infinite;
  }
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translate(120px, 0) rotate(0deg);
  }
  25% {
    transform: translate(375px, 340px) rotate(90deg);
  }
  50% {
    transform: translate(200px, 400px) rotate(180deg);
  }
  75% {
    transform: translate(0px, 150px) rotate(360deg);
  }
}

@keyframes floatAnimation1 {
  0%, 100% {
    transform: translate(270px, 0);
  }
  25% {
    transform: translate(170px, 400px);
  }
  50% {
    transform: translate(0px, 300px) ;
  }
  75% {
    transform: translate(410px, 120px);
  }
}

@keyframes floatAnimation2 {
  0%, 100% {
    transform: translate(400px, 400px) rotate(0deg);
  }
  25% {
    transform: translate(170px, 400px) rotate(45deg);
  }
  50% {
    transform: translate(0px, 350px) rotate(90deg);
  }
  75% {
    transform: translate(270px, 0px) rotate(180deg);
  }
}

@keyframes animClip {
  15% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
}
22% {
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%);
}
25% {
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0);
}
30% {
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}
}

// magic
.toggle {
  --size: 65px;
  appearance: none;
  outline: none;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: var(--font);
  transition: all 300ms;
  
  &:checked {
    --ray-size: calc(var(--size) * -0.4);
    --offset-orthogonal: calc(var(--size) * 0.65);
    --offset-diagonal: calc(var(--size) * 0.45);
    transform: scale(0.75);
    color: #FFEB8C;
    box-shadow: 
      inset 0 0 0 var(--size),
      calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
      var(--offset-orthogonal) 0 0 var(--ray-size),
      0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
      0 var(--offset-orthogonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
      var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
      var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
    ;
  }
}


.hg-theme-default{
  background: transparent !important;

  & .hg-button{
    margin: 5px;
    background: var(--secondary) !important;
  }
}

.hg-theme-default .hg-button{
  height: auto !important;
}

.hg-row{
  height: 95px !important;
}


.Toastify__toast-container {
  width: auto;
  padding: 16px;

  & .Toastify__toast {
    width: auto;
    min-width: 500px;
    height: 100px;
    font-size: 2em;
    background-color: var(--toastBackgroud) !important;
    color: var(--toastTextColor) !important;
  }

  & .Toastify__toast-icon {
    width: 30px;
    height: 30px;
  }

  & .Toastify__close-button{
    height: calc(100% - 7.5px);
    padding: 0 15px;
    & svg {
      width: 35px;
      height: 35px;
      fill: var(--toastIconColor) !important;
    }
  } 
}

.pulse-infinite {
  animation: pulse-animation-infinite 2s infinite;
}

@keyframes pulse-animation-infinite {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
  }
}




